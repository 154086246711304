import axios from 'axios'
import { config } from 'src/configs/constants'
import { Buffer } from 'buffer'
// import { headers } from 'pages/login/auth'

export function token() {
  return localStorage.getItem('app-token')
}

export function headers(custom) {
  return {
    headers: {
      ...custom,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token()}`,
    },
  }
}

export const debounceEvent =
  (fn, wait = 1000, time) =>
  (...args) =>
    clearTimeout(time, (time = setTimeout(() => fn(...args), wait)))

export const apiGet = async (urlPart, params) => {
  let response = {}

  const body = params.body || {}

  // console.log("body req: ", body);
  if (!('redirect' in params)) params.redirect = false
  await axios({
    method: 'get',
    url: `${config.url.API_URL}${urlPart}`,
    headers: { ...headers().headers, ...body },
    params: body,
  })
    .then((resp) => {
      response = resp.data
      // console.log("apiGet - response: ", response);
      if (params.done) {
        params.done(resp)
      }
    })
    .catch(function (error) {
      if (params.error) {
        params.error(error.response)
      }
      const errors = {
        submitResult: error.response,
      }
      localStorage.setItem('currentUrl', window.location.pathname)

      if (error.response.status === 401) window.location.href = '/'
      throw errors
    })
  return response
}

export const apiPost = async (urlPart, params) => {
  let response = {}
  const body = params.body || {}
  if (!('redirect' in params)) params.redirect = false

  console.log('body: ', body)
  console.log('urlPart: ', urlPart)
  console.log('API_URL: ', config.url.API_URL)
  await axios
    .post(`${config.url.API_URL}${urlPart}`, body, headers())
    .then((resp) => {
      response = resp.data
      if (params.done) {
        params.done(resp)
      }
    })
    .catch(function (error) {
      if (params.error) {
        params.error(error.response)
      }
      const errors = {
        submitResult: error.response,
      }
      localStorage.setItem('currentUrl', window.location.pathname)
      if (params.redirect) window.location.href = '/login'
      throw errors
    })
  return response
}

export const includeTrailingBackSlash = (str) => {
  str = str.replace(/\/?$/, '/')
  return str
}

export const isEmptyObject = (object) => {
  return Object.keys(object).length === 0 && object.constructor === Object
}

// export const superLog = (obj1, obj2, obj3, obj4, obj5) => {
//   console.log('================')
//   console.log('================')
//   console.log('================')
//   console.log(
//     JSON.stringify(obj1),
//     JSON.stringify(obj2) || '',
//     JSON.stringify(obj3) || '',
//     JSON.stringify(obj4) || '',
//     JSON.stringify(obj5) || ''
//   )
//   console.log('================')
//   console.log('================')
//   console.log('================')
// }

export const DateTime = (str) => {
  var data = new Date()

  // Guarda cada pedaço em uma variável
  var dia = data.getDate() // 1-31
  var dia_sem = data.getDay() // 0-6 (zero=domingo)
  var mes = data.getMonth() + 1 // 0-11 (zero=janeiro)
  var ano2 = data.getYear() // 2 dígitos
  var ano4 = data.getFullYear() // 4 dígitos

  var hora = data.getHours() // 0-23
  var min = data.getMinutes() // 0-59
  var seg = data.getSeconds() // 0-59
  var mseg = data.getMilliseconds() // 0-999
  var tz = data.getTimezoneOffset() // em minutos

  // Formata a data (note o mês + 1)
  if (dia < 9) {
    dia = '0' + dia
  }
  if (mes < 9) {
    mes = '0' + mes
  }

  var str_data = dia + '/' + mes + '/' + ano4

  // Formata a hora
  if (hora < 9) {
    hora = '0' + hora
  }
  if (min < 9) {
    min = '0' + min
  }

  var str_hora = hora + ':' + min

  // Formata dia e hora json ex:("2021-05-12T19:03:53.548Z")
  var date_time_json =
    ano4 + '-' + mes + '-' + dia + 'T' + hora + ':' + min + ':' + seg + 'Z'

  // Retorno baseado no paramentro passado na requisição
  if (str === 'time') {
    return str_hora
  }

  if (str === 'date') {
    return str_data
  }

  if (str === '') {
    return data
  }

  if (str === 'json') {
    return date_time_json
  }
}

export const weekByDate = (updatedAt, date, hour) => {
  const now = new Date() // Data de hoje
  const past = new Date(updatedAt) // Outra data no passado

  const diff = Math.abs(now.getTime() - past.getTime()) // Subtrai uma data pela outra
  const days = Math.ceil(diff / (1000 * 60 * 60 * 24))

  var day = now.getDay() === past.getDay()

  var week = ''
  var weekday = past.getDay()
  switch (weekday) {
    case 0:
      week = 'Domingo'
      break
    case 1:
      week = 'Segunda'
      break
    case 2:
      week = 'Terça'
      break
    case 3:
      week = 'Quarta'
      break
    case 4:
      week = 'Quinta'
      break
    case 5:
      week = 'Sexta'
      break
    case 6:
      week = 'Sábado'
      break
    default:
  }
  // {console.log("dias: " + days + " Semana: "+ week + " Hora: " + hour + " dia: " + date)}

  if (days > 7) {
    return date
  } else if (day) {
    return hour
  } else {
    return week
  }
}

export const Linkify = ({ text }) => {
  // Regex para detectar URLs
  const urlRegex = /(https?:\/\/[^\s]+)/g

  // Substituir URLs por elementos <a>
  const parts = text.split(urlRegex).map((part, index) => {
    if (urlRegex.test(part)) {
      return (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      )
    }
    return part
  })
  return <p>{parts}</p>
}
// export const weekByMessage = (updatedAt, date, hour) => {
//   const now = new Date() // Data de hoje
//   const past = new Date(updatedAt) // Outra data no passado

//   const diff = Math.abs(now.getTime() - past.getTime()) // Subtrai uma data pela outra
//   const days = Math.ceil(diff / (1000 * 60 * 60 * 24))

//   var day = now.getDay() === past.getDay()

//   var week = ''
//   var weekday = past.getDay()
//   switch (weekday) {
//     case 0:
//       week = 'Domingo'
//       break
//     case 1:
//       week = 'Segunda'
//       break
//     case 2:
//       week = 'Terça'
//       break
//     case 3:
//       week = 'Quarta'
//       break
//     case 4:
//       week = 'Quinta'
//       break
//     case 5:
//       week = 'Sexta'
//       break
//     case 6:
//       week = 'Sábado'
//       break
//     default:
//   }
//   // {console.log("dias: " + days + " Semana: "+ week + " Hora: " + hour + " dia: " + date)}

//   if (days > 7) {
//     return date
//   } else if (day) {
//     return 'Hoje'
//   } else {
//     return week
//   }
// }

export const decodeBase64 = (s) => {
  var e = {},
    i,
    b = 0,
    c,
    x,
    l = 0,
    a,
    r = '',
    w = String.fromCharCode,
    L = s.length
  var A = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'
  for (i = 0; i < 64; i++) {
    e[A.charAt(i)] = i
  }
  for (x = 0; x < L; x++) {
    c = e[s.charAt(x)]
    b = (b << 6) + c
    l += 6
    while (l >= 8) {
      ;((a = (b >>> (l -= 8)) & 0xff) || x < L - 2) && (r += w(a))
    }
  }
  return r
}

export const encodeBase64 = (e) => {
  const buf = Buffer.from(e, 'utf8')
  return buf.toString('base64')
}

export const urlLastPart = (url) => {
  let locationLastPart = url
  if (locationLastPart.substring(locationLastPart.length - 1) == '/') {
    locationLastPart = locationLastPart.substring(
      0,
      locationLastPart.length - 1
    )
  }
  locationLastPart = locationLastPart.substr(
    locationLastPart.lastIndexOf('/') + 1
  )
  return locationLastPart
}

export const copyToClipboard = (text) => {
  if (window.clipboardData && window.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData('Text', text)
  } else if (
    document.queryCommandSupported &&
    document.queryCommandSupported('copy')
  ) {
    var textarea = document.createElement('textarea')
    textarea.textContent = text
    textarea.style.position = 'fixed' // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea)
    textarea.select()
    try {
      return document.execCommand('copy') // Security exception may be thrown by some browsers.
    } catch (ex) {
      // console.warn("Copy to clipboard failed.", ex);
      return false
    } finally {
      document.body.removeChild(textarea)
    }
  }
}

// export const testemsg = (data) => {
//   console.log(data)

//   console.log(document.getElementsByClassName("chatWindow--body"))
// }

// export class teste {

//   constructor(mensagem = null){
//     if(mensagem){
//       this.msg = mensagem
//       return this.msg
//     }
//   };

//   get() {
//     return this.msg
//   }
// }

// Exemplo:
// debounceEvent(handleKeyUp, 500)
